import React from 'react';
import { addSwiperScreensApi } from '@src/libs/tools/addSwiperScreenApi';

export const useSwiperInit = (opts = {}) => {
	const { withScreenApi = true, onInit } = opts;

	const [screenCount, setScreenCount] = React.useState(0);
	const [activeScreen, setActiveScreen] = React.useState(0);
	const [activeIndex, setActiveIndex] = React.useState(0);
	const [isEnd, setIsEnd] = React.useState(false);

	const apiRef = React.useRef(null);

	const handleSlideChange = React.useCallback((swiper) => {
		setActiveIndex(swiper.activeIndex);
		setActiveScreen(swiper.activeScreen);
		if (swiper.previousIndex > swiper.activeIndex) {
			setIsEnd(false);
		}
	}, []);

	const handleEdge = React.useCallback((swiper) => {
		setActiveIndex(swiper.activeIndex);
		if (swiper.activeIndex > swiper.screens[swiper.activeScreen]) {
			setActiveScreen(swiper.activeScreen + 1);
		} else {
			setActiveScreen(swiper.activeScreen);
		}
		setIsEnd(swiper.isEnd);
	}, []);

	const handleSliderUpdate = React.useCallback((swiper) => {
		if (!swiper.screens) {
			addSwiperScreensApi(swiper);
		}
		setScreenCount(swiper.screens.length);
		setActiveScreen(swiper.activeScreen);
		setIsEnd(swiper.isEnd);
	}, []);

	const handleSwiper = React.useCallback(
		(swiper) => {
			if (withScreenApi) {
				addSwiperScreensApi(swiper);
				setScreenCount(swiper.screens.length);
			}

			swiper.on('slideChange', handleSlideChange);
			swiper.on('toEdge', handleEdge);
			swiper.on('update', (s) => {
				handleSliderUpdate(s);
			});
			swiper.on('resize', (s) => {
				handleSliderUpdate(s);
			});

			if (onInit) {
				onInit(swiper);
			}

			apiRef.current = swiper;
		},
		[
			withScreenApi,
			onInit,
			handleEdge,
			handleSlideChange,
			handleSliderUpdate,
		],
	);

	const slideNext = React.useCallback(() => {
		const swiper = apiRef.current;
		if (swiper) {
			if (swiper.params.slidesPerView === 'auto' && withScreenApi) {
				swiper.goToScreen(swiper.activeScreen + 1);
			} else {
				swiper.slideTo(
					swiper.activeIndex + swiper.params.slidesPerView,
				);
			}
		}
	}, [withScreenApi]);

	const slidePrev = React.useCallback(() => {
		const swiper = apiRef.current;
		if (swiper) {
			if (swiper.params.slidesPerView === 'auto' && withScreenApi) {
				const currentSlide = swiper.activeIndex;
				const targetSlide = swiper.screens[swiper.activeScreen];
				const targetScreen =
					currentSlide > targetSlide
						? swiper.activeScreen
						: swiper.activeScreen - 1;
				swiper.goToScreen(targetScreen);
			} else {
				swiper.slideTo(
					swiper.activeIndex - swiper.params.slidesPerView,
				);
			}
		}
	}, [withScreenApi]);

	return {
		handleSwiper,
		slideNext,
		slidePrev,
		screenCount,
		activeScreen,
		activeIndex,
		isEnd,
		isStart: activeIndex === 0,
		apiRef,
	};
};
